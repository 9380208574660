<template>
  <div
      :id="treeID"
      class="aside"
      @mouseover="cascaderMouse"
      @click.stop=""
  >
    <Tooltip placement="top" transfer>
      <div slot="content">
        <p style="text-align: center; width: 180px">按组织查找</p>
      </div>
      <Select
          v-model="orgCode"
          style="width: 200px"
          placeholder="输入关键字进行过滤"
          :label-in-value="true"
          filterable
          @on-change="getTree"
      >
        <Option
            v-for="(item, index) in orgList"
            :value="item.id"
            :key="index"
        >{{ item.name }}
        </Option>
      </Select>
    </Tooltip>
    <Tree
        v-if="cascaderFocusShow"
        :data="treeData"
        @on-select-change="handleTree"
        :load-data="loadData"
        :style="height!=null ? 'height:'+height+'px':''"
        class="fadeInDown animated"
    ></Tree>
  </div>
</template>

<script>
export default {
  name: "tree-list",
  props: {
    fixedmenu: Boolean,
    treeID: String,//
    fixedContentID: String,//,
    height: String
  },
  data() {
    return {
      allCommunityList: [],
      //异步加载社区
      loadingCommunity: false,
      //tree菜单显示与隐藏
      cascaderFocusShow: false,
      fixedMenu: false,
      treeData: [],
      nodeList: [],
      // nodeId: ["y112233"],
      nodeId: this.nodeId ? this.nodeId : [],
      custGlobalId: "",
      oemCode: "", //要从缓存拿
      communityCode: "",  //社区编码
      orgName: '',  //社区名称
      userSessionId: "",

      communityProjectCode: "",// 用于动员群推广
    };
  },
  created() {
    this.custGlobalId = parent.vue.loginInfo.userinfo.custGlobalId;
    this.oemCode = parent.vue.oemInfo.oemCode;
    this.userSessionId = parent.vue.loginInfo.userinfo.id
  },
  mounted() {
    // this.fixedTree(this.fixedmenu);
    this.$("body").on("click", this.pageClick);
    this.selectNode(this.userSessionId);
  },
  methods: {
    //异步加载社区
    loadingCommunityFn(query) {
      if (query !== '' && !Number(query)) {
        this.loadingCommunity = true;
        this.nodeList = this.allCommunityList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
        if (this.nodeList.length > 50) this.nodeList.length = 50;
        this.loadingCommunity = false;
      } else if (Number(query)) {
        return;
      } else {
        this.nodeList = [];
      }
    },
    //固定菜单树tree
    fixedTree(bool) {
      var that = this;
      if (bool) {
        if (this.fixedContentID != null) that.$("#" + this.fixedContentID).animate({left: that.$("#" + this.treeID).width() + 10}, 200);
        that.$("#" + this.treeID).css({
          "position": "absolute",
          "left": that.$("#" + this.treeID).offset().left
        }).animate({
          top: (this.fixedContentID != null ? that.$("#" + this.fixedContentID).offset().top + 10 : 0),
          left: 8
        });
      } else {
        that.$("#" + this.fixedContentID).animate({left: 0}, 200);
        that.$("#" + this.treeID).css({"position": "relative"}).animate({top: 0, left: 0});
      }
      this.fixedMenu = bool;
      this.cascaderFocusShow = bool;

    },
    //鼠标悬停显示tree菜单
    cascaderMouse() {
      this.cascaderFocusShow = true;
    },
    //页面点击隐藏tree菜单
    pageClick() {
      //判断是否固定菜单
      if (!this.fixedMenu) {
        this.cascaderFocusShow = false;
      }

    },
    selectNode(id) {
      if (id) {
        let staffId = id;
        this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
          staffId: staffId,
          orgCode: parent.vue.loginInfo.userinfo.orgCode
        })
            .then((res) => {
              if (res.code === "200" && res.dataList && res.dataList.length > 0) {
                this.nodeId = res.dataList[0].code != '' ? res.dataList[0].code.split(",") : [];   //修改判断条件，否则出错直接执行catch
                this.communityCode = res.dataList[0].code;
                this.communityProjectCode = res.dataList[0].projectCode;
                this.orgName = res.dataList[0].fullName;
                this.getTree(this.communityCode);   //第一次进来默认获取第一个社区数据
                this.nodeList = res.dataList.map((item) => {
                  return {
                    value: item.code,
                    label: item.fullName,
                    projectCode: item.projectCode
                  };
                });
                this.allCommunityList = JSON.parse(JSON.stringify(this.nodeList));
                if (this.nodeList.length > 50) {
                  this.nodeList.length = 50;
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
      } else {
        this.$Message.error("无法获取用户id");
      }
    },

    handleTree(data) {
      if (data && data[0]) {
        let temp = {
          pageSize: 20,
          page: 1,
          inviterFlag: this.inviterFlag,
          communityCode: this.communityCode,
          orgName: this.orgName,
          roadCode: data[0].orgCode, //街巷编码
          projectCode: data[0].projectCode, //楼栋编码
          regionType: data[0].regionType,
          orgId: data[0].orgId,
          regionId: data[0].regionId,
          communityProjectCode: this.communityProjectCode,
        };
        this.$emit("handleData", temp);
      } else {
        let temp = {
          inviterFlag: this.inviterFlag,
          orgName: this.orgName,
          communityCode: this.communityCode,
          pageSize: 20,
          page: 1,
          communityProjectCode: this.communityProjectCode,
        };
        this.$emit("handleData", temp);
      }
    },

    getTree(val) {
      this.nodeList.map((item, index) => {   //遍历数组，重新获取新的选中社区编码和名字
        if (item.value == val) {
          this.orgName = item.label;
          this.communityCode = item.value;
          this.communityProjectCode = item.projectCode;
          console.log(this.communityProjectCode);
        }
      })
      this.treeData = [];
      this.handleTree(); //第一次加载进来默认展示表格数据

      if (val && val.length > 0) {
        this.$get("/gx/pc/organization/selectOrganizationByOrgCode", {
          orgCode: this.communityCode,
        }).then((re) => {
          if (re && re.code === "200" && re.dataList != null) {
            let temp = re.dataList.map((item) => {
              return {
                title: item.orgName,
                orgCode: item.orgCode,
                oemCode: item.oemCode,
                orgName: item.orgName,
                parentId: item.parentId,
                orgId: item.orgId,
                loading: false,
                children: [],
              };
            });
            this.treeData = temp;
          }
        });
      }
    },
    loadData(item, callback) {
      let params = {
        oemCode: item.oemCode,
        orgId: item.orgId,
        parentId: item.regionId ? item.regionId : 0,   //如果父级是小区，传0，否则楼栋传regionId
        orgCode: item.orgCode,
      };
      this.$get("/old/api/pc/menu/findInformationBusinessTree", params)
          .then((res) => {
            console.log(res, '居民信箱')
            if (res.code === "200" && res.dataList != null) {
              let arr = res.dataList.map((i) => {
                if (i.regionType === "3") {
                  //1：小区 2：小区的楼栋 3：单独的楼栋"
                  return {
                    title: i.regionName,
                    regionId: i.regionId,
                    oemCode: this.oemCode,
                    orgCode: item.orgCode,
                    projectCode: i.regionCode,
                    regionType: i.regionType
                  };
                } else if (i.regionType === "2") {
                  return {
                    title: i.regionName,
                    regionId: i.regionId,
                    oemCode: this.oemCode,
                    orgCode: item.orgCode,
                    projectCode: i.regionCode,
                    regionType: i.regionType
                  };
                } else if (i.regionType === "1") {
                  return {
                    title: i.regionName,
                    regionId: i.regionId,
                    children: [],
                    loading: false,
                    oemCode: this.oemCode,
                    orgCode: item.orgCode,
                    projectCode: i.regionCode,
                    regionType: i.regionType
                  };
                }
              });
              item.children = arr;
              callback(item.children);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
</script>

<style lang="less" scoped>
.ivu-layout-sider {
  background: white;
}

.aside {
  margin: 0 5px;
  min-width: 200px;
  position: relative;

  /deep/ .ivu-select {
    position: absolute;
    left: 0;
    top: -15px;
    z-index: 999;
    min-width: 200px;

    input {
      padding: 0 26px;
    }

    .ivu-select-selection {
      background-color: #e8eaec !important;
      border: 1px solid #dcdee2;
    }
  }

  .lock {
    position: absolute;
    left: 6px;
    top: 5px;
    z-index: 1000;
    cursor: pointer;
  }

  .ivu-tree {
    height: calc(100vh - 100px);
    min-width: 200px;
    width: 100%;
    position: absolute;
    background: #fbfbfb;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 2px #ddd;
    border-radius: 0 0 5px 5px;
    z-index: 998;
    top: 30px;
    padding: 10px;
    overflow-y: auto;

    /deep/ ul {
      text-align: left;
      line-height: 1.3em;

      .ivu-tree-title-selected,
      .ivu-tree-title-selected:hover {
        background-color: #82d2af;
      }
    }
  ;

    /deep/ div {
      text-align: center;
    }
  }
}
</style>
